<template>
  <div class="home">
    <div class="top">
      <img src="../assets/images/slogan.png" />
    </div>
    <div class="cont">
      <div class="cimg">
        <img src="../assets/images/bgtop.png" />
      </div>
      <div class="cm">
        <div class="itemBox">
          <ul class="tableHeader">
            <li class="flex1">排名</li>
            <li class="flex4">用户</li>
            <li class="flex2 tr">分数</li>
            <li class="flex2 tr">用时</li>
          </ul>
          <div class="tableBody">
            <ul v-for="item in list1" :key="item.id">
              <li class="flex1 rank">{{ item.rank }}</li>
              <li class="flex4">
                <div class="tl"><img :src="item.userImage" /></div>
                <div class="tr">
                  <p>{{ item.userName }}</p>
                  {{ item.deptName }}
                </div>
              </li>
              <li class="flex2 tr">
                <span class="score">{{ item.maxScore }}</span>
              </li>
              <li class="flex2 num tr">{{ item.userAnswerQuestionsTime }}"</li>
            </ul>
          </div>
        </div>
        <div class="itemBox">
          <ul class="tableHeader">
            <li class="flex1">排名</li>
            <li class="flex4">用户</li>
            <li class="flex2 tr">分数</li>
            <li class="flex2 tr">用时</li>
          </ul>
          <div class="tableBody">
            <ul v-for="item in list2" :key="item.id">
              <li class="flex1 rank">{{ item.rank }}</li>
              <li class="flex4">
                <div class="tl"><img :src="item.userImage" /></div>
                <div class="tr">
                  <p>{{ item.userName }}</p>
                  {{ item.deptName }}
                </div>
              </li>
              <li class="flex2 tr">
                <span class="score">{{ item.maxScore }}</span>
              </li>
              <li class="flex2 num tr">{{ item.userAnswerQuestionsTime }}"</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cimg">
        <img src="../assets/images/bgbottom.png" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl, examId } from "../../public/config";
export default {
  name: "HomeView",
  data() {
    return {
      list1: [],
      list2: [],
      timer: null,
    };
  },
  methods: {
    getList() {
      axios
        .post(baseUrl + "/exam/api/paper/paper/ranking", { id: examId })
        .then((res) => {
          let arr1 = [];
          let arr2 = [];
          res.data.data.ranking.forEach((i, index) => {
            if (index < 5) {
              arr1.push(i);
            } else {
              arr2.push(i);
            }
          });
          this.list1 = arr1;
          this.list2 = arr2;
        });
    },
    init() {
      this.timer = setInterval(() => {
        this.getList();
      }, 10000);
    },
  },
  created() {
    this.getList();
    clearInterval(this.timer);
    this.init();
  },
};
</script>
<style lang="less">
@font-face {
  font-family: "FZLTCHJW";
  src: url("../assets/font/FZLTCHJW.TTF");
}
@font-face {
  font-family: "FZLTZHJW";
  src: url("../assets/font/FZLTZHJW.ttf");
}
@font-face {
  font-family: "FZLTHJW";
  src: url("../assets/font/FZLTHJW.ttf");
}
@font-face {
  font-family: "DIN-Bold";
  src: url("../assets/font/DIN-Bold.ttf");
}
@font-face {
  font-family: "DIN-Medium";
  src: url("../assets/font/DIN-Medium.otf");
}
* {
  box-sizing: border-box;
}
img {
  vertical-align: top;
}
html,
body,
#app,
.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  background: url("../assets/images/pageBg.png") no-repeat scroll 0 0;
  background-size: 100% 100%;
  color: #4a4a4a;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top {
    text-align: center;
    margin-top: 40px;
    img {
      width: 37%;
      height: auto;
    }
  }
  .cont {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84%;
    margin: -40px auto 0;
    .cimg {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .cm {
      flex: 1;
      width: 100%;
      padding: 0 3%;
      background: url("../assets/images/bgcont.png") repeat-y scroll 0 0;
      background-size: 100% auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      .itemBox {
        display: flex;
        flex-direction: column;
        width: 45%;
        height: 100%;
        .tableHeader {
          display: flex;
          align-items: flex-start;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 100%;
          border-bottom: #4a4a4a solid 2px;
          li {
            text-align: center;
            font-size: 20px;
            padding: 10px;
            font-family: "FZLTCHJW";
            &.tr {
              text-align: right;
            }
          }
        }
        .tableBody {
          flex: 1;
          width: 100%;
          ul {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            height: 20%;
            border-bottom: rgba(74, 74, 74, 0.5) solid 1px;
            &:nth-child(5) {
              border-bottom: #4a4a4a solid 2px;
            }
            li {
              font-size: 26px;
              padding: 10px;
              overflow: hidden;
              text-align: center;
              &.flex4 {
                display: flex;
                align-items: center;
                text-align: center;
              }
              .tl {
                margin-right: 10px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ccc;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                }
              }
              .tr {
                flex: 1;
                text-align: left;
                font-size: 14px;
                font-family: "FZLTHJW";
                overflow: hidden;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 26px;
                  font-family: "FZLTZHJW";
                  margin-bottom: 5px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }
              &.tr {
                text-align: right;
              }
              .score {
                font-size: 44px;
                color: #1a49d6;
                font-family: "DIN-Bold";
              }
              &.rank {
                font-family: "DIN-Bold";
              }
              &.num {
                font-family: "DIN-Medium";
              }
            }
          }
        }
      }
    }
  }
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex4 {
  flex: 4;
}
</style>
